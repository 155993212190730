<template>
  <EditableControlWrapper
    :value="value"
    :has-label="floatingLabel"
    class="EditableControlInput"
  >
    <FormField
      :validator="validator"
      :attribute="attribute"
      :label="label"
      :hide-label="hideLabel"
      :name="name"
    >
      <input
        ref="input"
        v-model="computedValue"
        :placeholder="placeholder"
        class="input"
        type="text"
        @keypress.enter="save"
        @blur="save"
        @keydown.esc="cancel"
      >
    </FormField>
  </EditableControlWrapper>
</template>

<script>
import FormField from '@hypefactors/shared/js/components/forms/FormField'
import EditableControlPrototype from '@hypefactors/shared/js/components/forms/controls/EditableControlPrototype'

export default {
  name: 'EditableControlInput',

  components: {
    FormField
  },

  mixins: [EditableControlPrototype]
}
</script>
