<template>
  <div
    ref="EditableField"
    :class="{ 'EditableField--inline': inlinePlaceholder, 'EditableField--editing': isEditing }"
    :style="computedStyles"
    tabindex="0"
    class="EditableField"
    @focus.prevent.stop="startEditing"
    @blur.capture="handleBlurBeforeInput"
  >
    <div
      v-if="isEditing"
      v-click-away="save"
      class="EditableField__field"
    >
      <component
        :is="control"
        ref="controlField"
        v-model="currentValue"
        :floating-label="floatingLabel"
        :placeholder="placeholder"
        :hide-label="hideLabel"
        :attribute="attribute"
        :validator="validator"
        :class="controlClass"
        :label="label"
        :name="name"
        v-bind="$attrs"
        @save="save"
        @cancel="cancelEditing"
      />
      <slot name="post-control-field" />
      <span
        v-if="hasCancelButton"
        class="EditableField__cancel"
        @click="cancelEditing"
      >
        <i class="hf hf-close" />
      </span>
    </div>

    <div
      v-else
      :class="{ 'EditableField__displayElement--hasErrors': hasErrors }"
      class="EditableField__displayElement"
      @click="startEditing"
    >
      <div
        v-if="isValueEmpty && emptyText"
        class="EditableField__placeholder"
      >
        <span>{{ emptyText }}</span>
        <span
          v-if="showPencil && !disabled && !isEditing"
          class="EditableField__edit-icon has-text-primary"
        >
          <v-icon type="pencil" />
        </span>
      </div>

      <div
        v-else
        class="EditableField__preview is-position-relative"
      >
        <slot>
          {{ value }}
        </slot>
        <span
          v-if="showPencil && !disabled && !isEditing"
          class="EditableField__edit-icon has-text-primary"
        >
          <v-icon type="pencil" />
        </span>
      </div>
      <FormField
        v-if="!hideDisplayValidation"
        :validator="validator"
        :attribute="attribute"
        :label="label"
        :name="name"
        hide-label
      />
    </div>
  </div>
</template>

<script>
import _isEqual from 'lodash/isEqual'
import _cloneDeep from 'lodash/cloneDeep'

import EditableControlInput from '@hypefactors/shared/js/components/forms/controls/EditableControlInput.vue'
import EditableControlTextarea from '@hypefactors/shared/js/components/forms/controls/EditableControlTextarea.vue'
import FormField from '@hypefactors/shared/js/components/forms/FormField'
import VIcon from '@hypefactors/shared/js/components/core/VIcon'

import { isDescendant } from '@hypefactors/shared/js/utils'

/**
 * Allows for editing and previewing fields on click
 * @module EditableField
 * @emits input - on clickaway, or control component save event
 * @emits cancel - on esc or other cancel trigger from the control component
 */
export default {
  name: 'EditableField',

  components: {
    EditableControlInput,
    EditableControlTextarea,
    FormField,
    VIcon
  },

  props: {
    value: {
      type: [String, Object],
      default: ''
    },

    emptyText: {
      type: String,
      default: ''
    },

    control: {
      type: String,
      default: 'EditableControlInput'
    },

    isEmpty: {
      type: Function,
      default: (value) => {
        if (value && typeof value === 'object') {
          return !Object.values(value).some(Boolean)
        }
        return !value
      }
    },

    validator: {
      type: Object,
      default: undefined
    },

    label: {
      type: String,
      default: ''
    },

    name: {
      type: String,
      default: ''
    },

    attribute: {
      type: String,
      default: ''
    },

    /**
     * Hides the label
     */
    hideLabel: {
      type: Boolean,
      default: false
    },

    /**
     * Sets the label as floated
     */
    floatingLabel: {
      type: Boolean,
      default: false
    },

    /**
     * Sets the Placeholder
     */
    placeholder: {
      type: String,
      default: ''
    },

    /**
     * Sets a class on the control component
     */
    controlClass: {
      type: String,
      default: ''
    },

    /**
     * Toggles the visibility of the X cancel button
     */
    hasCancelButton: {
      type: Boolean,
      default: false
    },

    /**
     * Should the field auto close it self after save event
     */
    autoStopEdit: {
      type: Boolean,
      default: false
    },

    inlinePlaceholder: {
      type: Boolean,
      default: false
    },

    hideDisplayValidation: {
      type: Boolean,
      default: false
    },

    addMinHeight: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    showPencil: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      isEditing: false,
      /* value prop to edit locally */
      currentValue: null,
      /* value prop cached locally */
      cachedValue: null,
      elementHeight: 0
    }
  },

  computed: {
    /**
     * Returns whether the value has any data in it
     * @returns {Boolean}
     */
    isValueEmpty () {
      return this.isEmpty(this.value)
    },

    /**
     * Returns whether the field has errors or not
     * @returns {Boolean}
     */
    hasErrors () {
      return !this.hideDisplayValidation && (this.validator && this.validator.$error)
    },

    computedStyles () {
      if (!this.addMinHeight) return {}
      return {
        minHeight: `${this.elementHeight}px`
      }
    }
  },

  mounted () {
    this.storeHeight()
  },

  updated () {
    this.storeHeight()
  },

  methods: {
    async startEditing () {
      if (this.disabled || this.isEditing) {
        return
      }
      this.currentValue = _cloneDeep(this.value)
      this.cachedValue = _cloneDeep(this.value)

      this.isEditing = true
      await this.$nextTick()
      this.focus()
    },

    stopEditing () {
      this.isEditing = false
    },

    save () {
      const isTheSame = _isEqual(this.currentValue, this.value)
      // if data has not changed and has an error, just exit
      if (isTheSame && this.hasErrors) {
        return
      }
      // if data has not changed, just stop editing
      if (isTheSame) {
        this.stopEditing()
      } else {
        this.$emit('input', this.currentValue)
        if (this.autoStopEdit && !this.hasErrors) {
          this.stopEditing()
        }
      }
    },

    cancelEditing () {
      this.$emit('cancel', this.cachedValue)
      this.stopEditing()
    },

    /**
     * Focuses the ControlComponent
     * Awaits async components to load
     * @returns {Promise<void>}
     */
    async focus () {
      // Get the component we are dynamically loading
      const componentDefinition = this.$options.components[this.control]
      // Check if it has already been loaded or not
      if (!componentDefinition.resolved && typeof componentDefinition === 'function') {
        // Await the component to load
        await componentDefinition()
      }
      await this.$nextTick()
      // do what ever with the ref
      this.$refs.controlField && this.$refs.controlField.focus()
    },

    /**
     * Captures the blur event from the parent before it has been handled by the child.
     * Allows us to stop the blur if the target was from within the `.EditableField__field` component
     * @param {FocusEvent} event
     */
    handleBlurBeforeInput (event) {
      if (this.$refs.EditableField === event.relatedTarget || isDescendant(this.$refs.EditableField, event.relatedTarget)) {
        event.stopPropagation()
      }
    },

    async storeHeight () {
      if (!this.addMinHeight) return
      await this.$nextTick()
      this.elementHeight = this.$el ? this.$el.clientHeight : 0
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

.EditableField {
  &--inline {
    .EditableField__preview, .EditableField__placeholder {
      display: inline-block;
    }
  }

  &__field {
    position: relative;
  }

  &__cancel {
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
  }

  &__edit-icon {
    position: absolute;
    top: -5px;
    right: 0px;
    cursor: pointer;
    font-size: $size-6;
    @include transit();
  }

  &__preview, &__placeholder {
    padding-right: 15px;
    cursor: pointer;
    position: relative;
  }

  &__placeholder {
    color: $input-placeholder-color;
  }

  &__displayElement {
    &--hasErrors {
      .EditableField__placeholder, .EditableField__preview {
        color: $danger !important;
      }
    }
  }
}
</style>
