<template>
  <editable-control-wrapper
    :value="value"
    :has-label="floatingLabel"
    class="EditableControlTextarea"
  >
    <form-field
      :validator="validator"
      :attribute="attribute"
      :label="label"
      :hide-label="hideLabel"
      :name="name"
    >
      <autosize-textarea
        ref="input"
        v-model="computedValue"
        :placeholder="placeholder"
        rows="1"
        class="textarea"
        @keydown.esc="cancel"
        @blur="save"
      />
    </form-field>
    <!-- Hiding for now as it may be used at a later point in time -->
    <div
      v-if="showSaveButton"
      class="has-text-right"
    >
      <button
        class="button is-primary"
        type="button"
        data-testid="EditableControlTextarea__SaveButton"
        @click="save"
      >
        {{ $t('pages.newsroom_edit.save_contact_info') }}
      </button>
    </div>
  </editable-control-wrapper>
</template>

<script>
import EditableControlPrototype from '@hypefactors/shared/js/components/forms/controls/EditableControlPrototype'
import AutosizeTextarea from '@hypefactors/shared/js/components/core/AutosizeTextarea'

/**
 * Generates an AutosizeTextarea Control Component
 * @module EditableControlTextarea
 */
export default {
  name: 'EditableControlTextarea',
  components: { AutosizeTextarea },
  mixins: [EditableControlPrototype],
  props: {
    showSaveButton: {
      type: Boolean,
      default: false
    }
  }
}
</script>
