<template functional>
  <div
    :class="[data.staticClass, data.class, { 'EditableControl--noValue': !props.value, 'EditableControl--floatingLabel': !!props.hasLabel }]"
    v-bind="data.attrs"
    class="EditableControl"
  >
    <slot />
  </div>
</template>

<script>
/**
 * @module EditableControlWrapper
 */
export default {
  name: 'EditableControlWrapper',
  props: {
    value: {
      type: [Object, Array, String, Number],
      default: null
    },
    hasLabel: {
      type: Boolean,
      default: false
    }
  }
}
</script>
