import EditableControlWrapper from './EditableControlWrapper'

/**
 * Prototype mixin to be extended to create Control Components for {@see module:EditableField}
 * @emits input
 * @emits save
 * @module EditableControlPrototype
 */
export default {
  components: {
    /**
     * Adds floating label to top of control inputs
     */
    EditableControlWrapper
  },

  props: {
    /**
     * The value parameter
     */
    value: {
      type: String,
      default: ''
    },

    /**
     * Sets the validator on the FormField
     */
    validator: {
      type: Object,
      default: undefined
    },

    /**
     * Sets the label on the FormField
     */
    label: {
      type: String,
      default: ''
    },

    /**
     * Sets the label to float
     */
    floatingLabel: {
      type: Boolean,
      default: false
    },

    /**
     * Hides the label from the element
     */
    hideLabel: {
      type: Boolean,
      default: false
    },

    /**
     * Sets the placeholders on the inputs
     */
    placeholder: {
      type: String,
      default: ''
    },

    name: {
      type: String,
      default: ''
    },

    attribute: {
      type: String,
      default: ''
    }
  },

  computed: {
    computedValue: {
      get () {
        return this.value
      },
      set (value) {
        this._handleInput(value)
      }
    }
  },

  methods: {
    /**
     * Triggers the validation and updates the value in the parent
     * @param value
     */
    _handleInput (value) {
      this.validator && this.validator.$touch()
      this.$emit('input', value)
    },

    /**
     * Called to trigger save on parent
     */
    save () {
      this.$emit('save')
    },

    /**
     * Called to cancel the еditing
     */
    cancel () {
      this.$emit('cancel')
    },

    /**
     * Default focus method.
     * Can be overridden by extending components
     */
    focus () {
      this.$refs.input.focus()
    }
  }
}
